import React, {  useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import logo from './img/logo.png'
import arrow from './img/arrow.png'
import footer from './img/footer.png'
import logoFooter from './img/logo_footer.png'
import { EnvelopeAtFill, Facebook, Linkedin, TelephoneFill, Whatsapp } from 'react-bootstrap-icons'

//const UPDATE_API = 'https://datos.kaibiin.com/api/ins'
const UPDATE_API = 'http://eng6.kaibiin.com:7453/api/ins'

function Footer() {
  const [ service, setService ] = useState(null)

  const [isLoading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")
  const [path, setPath] = useState(window.location.pathname)

  const [showMessage, setShowMessage] = useState(false)
  const [success, setSuccess] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [show, setShow] = useState(false);

  const [db, setDb] = useState(null);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
  }, []);

  const updateData = (e) => {
    e.preventDefault();
    var json = { name: name, email: email, phone: phone, comments: comments }
    setLoading( true )

    axios({
      method: 'post',
      url: UPDATE_API,
      headers: { 'content-type': 'application/json' },
      data: json
    })
    .then(result => {

      if(result.data && result.data.sta && result.data.sta == 200 ) {
        setShowMessage( true )
        setLoading( false )
        cleanForm()

        setTitle( "Mensaje enviado" )
        setMessage( "Hemos recibido tu mensaje, nos comunicaremos contigo a la brevedad." )
      } else errorMsg()
      
    })
    .catch(error => {
      errorMsg()
      console.log("error", error.message)
    })
  }

  const errorMsg = () => {
    setShowMessage( true )
    setLoading( false )

    setTitle( "Ocurrió un error" )
    setMessage( "No hemos podido recibir tu mensaje, de favor intentalo de nuevo mas tarde." )
  }

  const cleanForm = () => {
    setName("")
    setPhone("")
    setEmail("")
    setComments("")
  }

  return (
    <div>

      <Modal show={showMessage} onHide={ () => setShowMessage(false) }>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={ () => setShowMessage(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='footer'>
        <Container>
          <br/>
          <Row>
            <div align='center' className='section-grl'>
              <Container>
                <Row>
                  <Col lg={ 2 } md={ 3 } xs={ 12 } className={'no-padding services-items service-button acc-a' }>
                    <a href={ '/control' } className='vertical-center'>
                      <Container>
                        <Row className='align-items-center'>
                          <Col lg={ 10 } md={ 10 } xs={ 10 }>
                            <div className=""> Control de Temperatura </div> 
                          </Col>
                          <Col lg={ 2 } md={ 2 } xs={ 1 } >
                            <img src={ arrow } className='arrow-small'/>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    <div className={ "background-comp acc-a" } />
                  </Col>
                  <Col lg={ 2 } md={ 3 } xs={ 12 } className={ 'no-padding services-items service-button acc-b card-shadow' }>
                    <a href={ '/tank' } className='vertical-center'>
                      <Container>
                        <Row className='align-items-center'>
                          <Col lg={ 10 } md={ 10 } xs={ 10 }>
                            <div className=""> Track Tank </div> 
                          </Col>
                          <Col lg={ 2 } md={ 2 } xs={ 1 }>
                            <img src={ arrow } className='arrow-small'/>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    <div className={ "background-comp acc-b" } />
                  </Col>
                  <Col lg={ 2 } md={ 3 } xs={ 12 } className={ 'no-padding services-items service-button acc-a card-shadow' }>
                    <a href={ '/trackit' } className='vertical-center'>
                      <Container>
                        <Row className='align-items-center'>
                          <Col lg={ 10 } md={ 10 } xs={ 10 }>
                            <div className=""> Track it </div> 
                          </Col>
                          <Col lg={ 2 } md={ 2 } xs={ 1 }>
                            <img src={ arrow } className='arrow-small'/>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    <div className={ "background-comp acc-a" } />
                  </Col>
                  <Col lg={ 2 } md={ 3 } xs={ 12 } className={ 'no-padding services-items service-button acc-b card-shadow' }>
                    <a href={ '/satelital' } className='vertical-center'>
                      <Container>
                        <Row className='align-items-center'>
                          <Col lg={ 10 } md={ 10 } xs={ 10 }>
                            <div className=""> Satelital Track </div> 
                          </Col>
                          <Col lg={ 2 } md={ 2 } xs={ 1 }>
                            <img src={ arrow } className='arrow-small'/>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    <div className={ "background-comp acc-b tablet-class" } />
                  </Col>
                  <Col lg={ 2 } md={ 3 } xs={ 12 } className={ 'no-padding services-items service-button acc-a card-shadow' }>
                    <a href={ '/solar' } className='vertical-center'>
                      <Container>
                        <Row className='align-items-center'>
                          <Col lg={ 10 } md={ 10 } xs={ 10 }>
                            <div className=""> Solar Track </div> 
                          </Col>
                          <Col lg={ 2 } md={ 2 } xs={ 1 }>
                            <img src={ arrow } className='arrow-small'/>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    <div className={ "background-comp acc-a" } />
                  </Col>
                  <Col lg={ 2 } md={ 3 } xs={ 12 } className={ 'no-padding services-items service-button acc-b card-shadow' }>
                    <a href={ '/dashcam' } className='vertical-center'>
                      <Container>
                        <Row className='align-items-center'>
                          <Col lg={ 10 } md={ 10 } xs={ 10 }>
                            <div className=""> Dash Cam </div> 
                          </Col>
                          <Col lg={ 2 } md={ 2 } xs={ 1 }>
                            <img src={ arrow } className='arrow-small'/>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
          </Row>
        </Container>
        <br/>
        <Container className=''>
          <Row>
            <Col md={ 5 }>
              <Form id="contactForm" onSubmit={ updateData }>
                <Row>
                  <Col>
                    <div className='footer-title'> TOMA EL CONTROL </div>
                    <div className='footer-desc'> Y OPTIMIZA TUS FLOTILLAS </div>
                  </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                  <Col md={ 12 } xs={ 12 }>
                    <div style={{textAlign: "left"}}>
                      <Form.Group controlId="formGridEmail" className='mb-3'>
                        <Form.Control required name="name" value={name} placeholder="Nombre:" onChange={ (e) => setName( e.target.value ) }/>
                      </Form.Group>

                      <Form.Group controlId="formGridEmail" className='mb-3'>
                        <Form.Control required type="number" value={phone} name="phone" placeholder="Teléfono:" onChange={ (e) => setPhone( e.target.value ) }/>
                      </Form.Group>

                      <Form.Group controlId="formGridEmail" className='mb-3'>
                        <Form.Control required type="email" value={email} name="email" placeholder="E-mail:" onChange={ (e) => setEmail( e.target.value ) }/>
                      </Form.Group>

                      <Form.Group controlId="formGridEmail" className='mb-3'>
                        <Form.Control required name="comments" as="textarea" value={comments} rows="4" placeholder="Servicio de su interés:" onChange={ (e) => setComments( e.target.value ) }/>
                      </Form.Group>
                      <br/>

                      <div align="left"><Button disabled={isLoading} className="form-button" type="submit" >{isLoading ? 'Enviando…' : 'ENVIAR'}</Button></div>
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col>
                    <div className='mb-2'><a href={ 'mailto:ventas@wtracking.com.mx' }><EnvelopeAtFill className='footer-icon' />&nbsp;&nbsp; <span className='footer-contact'>ventas@wtracking.com.mx</span> </a></div>
                    <div className='mb-2'><a href={ 'https://wa.me/5215548846108' }><Whatsapp className='footer-icon' />&nbsp;&nbsp; <span className='footer-contact'>55 48 84 61 08</span> </a></div>
                    <div className='mb-2'><a href={ 'tel:5591552545' }><TelephoneFill className='footer-icon' />&nbsp;&nbsp; <span className='footer-contact'>55 9155 2545</span> </a></div>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col md={ 7 }>
              <div> <img src={footer} className='img-100'/> </div>
            </Col>
          </Row>
        
        </Container>

        <br/>

        <Container className='footer'>
          <Row>
            <Col>
              <hr className='hr-line'/>
            </Col>
          </Row>
          <br/>
          <Row className='align-items-center'>
            <Col md={ 4 } xs={ 12 }>
              <div onClick={ () => window.location.href = '/' } className='footer-logo-style' style={{ cursor: 'pointer' }}>
                <img src={ logoFooter } className='img-100 footer-logo' />
              </div>
            </Col>
            <Col md={ 4 } xs={ 6 }>
              <div className='footer-policy' onClick={ () => setShow( true ) }>
                Aviso de Privacidad
              </div>
            </Col>
            <Col md={ 4 } xs={ 6 }>
              <div align="right">
                <span onClick={ () => window.open( 'https://www.facebook.com/wtracking', '_blank' ) } style={{ cursor: 'pointer' }} ><Facebook className='footer-rrss-icons' />&nbsp;&nbsp; </span>
                <span onClick={ () => window.open( 'https://www.linkedin.com/company/wtracking/', '_blank' ) } style={{ cursor: 'pointer' }} ><Linkedin className='footer-rrss-icons' />&nbsp;&nbsp; </span>
                <span className='footer-policy'> WTracking </span>
              </div>
            </Col>
          </Row>
        </Container>
        <br/><br/>

      </div>
      
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="footer-privacity">
          <img alt="" id="brand_image" src={ logo } width="auto" height="60" className="d-inline-block brand-image-start"/>
          <br/>
          <br/>
          <br/>
          <h3>Aviso de privacidad</h3>
          <p>
            <div>Grupo Comercial Kimisa S. A. de C. V., (en lo sucesivo “La Empresa”), con domicilio ubicado en Ferrocarril Central No. 522 int 8, Col. Victoria de las Democracias, Azcapotzalco, CDMX, C.P. 02810, suscribe el presente aviso de privacidad en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”).</div>
            <br/>
            <div>Los datos personales incluyendo los sensibles, que usted ha proporcionado o proporcionara directamente o a través de medios electrónicos a “La Empresa”, han sido, serán o seguirán siendo recabados y serán tratados por “La Empresa” bajo los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, de conformidad con lo dispuesto por la Ley, con el propósito de cumplir aquellas obligaciones que se derivan de la relación jurídica existente entre usted como titular de los datos personales y “La Empresa” como responsable de los mismos, “La Empresa” hace de su conocimiento que los datos personales proporcionados por usted, incluyendo los sensibles, serán utilizados para integrar su expediente como cliente; para prestar los servicios que pudieran llegar a ser o hayan sido contratados y se encontraran debidamente protegidos a través de medidas de seguridad, previniendo el uso o divulgación indebida de los mismos.</div>
            <br/>
            <div>Usted como Titular de dichos datos, podrá en cualquier momento revocar el consentimiento que ha otorgado a “La Empresa” para el tratamiento de sus datos personales, limitar el uso o divulgación de los mismos, así como ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición, mediante notificación fehaciente y por escrito a “La Empresa”, la cual deberá ser presentada y recabada constancia de ello, en el domicilio señalado en el presente aviso, debiendo contener su nombre y firma autógrafa, así como un domicilio u otro medio para comunicarle la respuesta a su solicitud, a la cual deberá de acompañar los documentos oficiales que acrediten la identidad del titular, descripción clara y precisa de los datos personales respecto de los cuales ejercitará los derechos que les confiere la Ley.</div>
            <br/>
            <div>El presente aviso de privacidad podrá ser modificado o actualizado unilateralmente por “La Empresa”, informándole al Titular de dichos cambios a través de su página web.</div>
          </p>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default Footer;