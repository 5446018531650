import React, {  useState, useEffect } from 'react';
import { services } from './serviceJson';

import { Parallax, Background } from 'react-parallax';

import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import back from "./img/back.png"
import shadow from "./img/services/shadow.png"

import trackit from "./img/services/trackit.png"
import tank from "./img/services/tank.png"
import solar from "./img/services/solar.png"
import satelital from "./img/services/satelital.png"
import dashcam from "./img/services/dashcam.png"
import control from "./img/services/control.png"


const SERVICE_PATH = [ 'control', 'dashcam', 'solar', 'satelital', 'trackit', 'tank' ]
//const PATH = 'http://localhost:3000/services/'
const PATH = 'http://eng6.kaibiin.com/services/'

function Services({ path }) {
  const [ service, setService ] = useState(services[ path ])
  const [ strength, setStrength ] = useState( 70 )
  const [ image, setImage ] = useState(  )

  useEffect(() => {
    var w = window.innerWidth;
    console.log( "width", w )

    if( w <= 460 ) setStrength( 0 )

    if( path == 'trackit' ) setImage( trackit )
    else if( path == 'tank' ) setImage( tank )
    else if( path == 'solar' ) setImage( solar )
    else if( path == 'satelital' ) setImage( satelital )
    else if( path == 'dashcam' ) setImage( dashcam )
    else if( path == 'control' ) setImage( control )

  }, []);

  return (
    <div>
      {/* <div className='parallax-conteiner'>
        <Parallax
            blur={0}
            bgImage={ PATH + path + '.png'}
            strength={ strength }
            bgClassName='service-background'
        >
            <div className='parallax-item-height' />
        </Parallax>
        <div className='btn-back' onClick={ () => window.location.href = '/'}>
          <img src={ back } className='back-img' />
        </div>
      </div> */}

      <div className='parallax-conteiner'>
        <img src={ image } className='img-100' />
      </div>

      <div className='parallax-conteiner-mobile'>
        <img src={ image } className='img-100' />
      </div>

      <div className='section-simple'>
        <Container>
          <Row>
            <Col md={ 8 }>
              <div align="center"> <img src={ service.title } className='service-title' /></div>
              <br/><br/>
              <div className='home-desc'>{ service.desc }</div>
              <br/><br/><br/>
              <Container>
                <Row>
                  { service.items.map( item => (
                    <Col lg={ 2 } md={ 3 } xs={ 6 } align="center">
                      <div><img src={ item.img } className='bullet-service' /></div>
                      <div className='bullet-text'>{ item.name }</div>
                    </Col>
                  )) }
                </Row>
              </Container>
            </Col>

            <Col md={ 4 }>
              <div align="center">
                <img src={ service.banner } className={ service.bannerClass + ' banner-mobile' } />
              </div>
            </Col>
          </Row>
        </Container>
      </div>


    </div>
  );
}

export default Services;